<template>
  <div class="home homeXinke">
    <div class="ztBox">
      <a href="/specail?t=2" title="党纪学习教育">
        <img src="../assets/img/zt_djxxjy.jpg" alt="">
      </a>
      <a href="/specail" title="“三抓三促”行动进行时">
        <img src="../assets/img/zt_szsc.jpg" alt="">
      </a>
    </div>
    <div class="swiper-container bannerHome">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_xxesd.png" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_lz.png" alt="">
        </div>
        <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner_fj.png" alt="">
        </div>
        <!-- <div class="swiper-slide">
          <img class="img_c1" src="../assets/img/banner2.jpg" alt="">
        </div> -->
      </div>
      <div class="swiper-pagination swiper-pagination-banner"></div>
    </div>
    <div class="mian mainHome">

      <div class="xinkeSec01">
        <div class="wrap">
          <div class="xinkeHd">
            <div class="xinkeHdTitle">
              发展战略
            </div>
            <div class="xinkeHdSub">
              DEVELOPMENT STRATEGY
            </div>
            <div class="xinkeHdTxt">
              <p>
                公司围绕智慧交通和智能物联两大业务领域，针对行业发展的难点痛点，研究行业发展的前沿核心需 求，广泛联合产业上下游企业和行业专家，开展解决方案、 技术应用和商业模式等前瞻性研究，公司策划具有战略性的 重大项目，实现跨领域产品技术的整合以及大数据的综合运用。
              </p>
            </div>
          </div>

        </div>
      </div>

      <div class="xinkeSec02">
        <div class="xinkeSec02Bg">
          <!-- <img src="../assets/img/banner4.jpg" alt=""> -->
          <video style="width: 100%;height: 400px;object-fit: fill;position: absolute;top:0;z-index: -100;" muted="" autoplay="autoplay" loop="loop">
            <source src="../assets/video/1.mp4" type="video/mp4">您的浏览器不支持 video 标签。
          </video>
        </div>
        <div class="wrap">
          <div class="xinkeHd xinkeHdFff">
            <div class="xinkeHdTitle">
              核心业务
            </div>
            <div class="xinkeHdSub">
              CORE BUSINESS
            </div>
          </div>
          <div class="xinkeSec02Box">
            <el-row :gutter="30">
              <el-col :span="8" v-for="(item, index) in businessListData" :key="index">
                <div class="xinkeSec02Inner">
                  <div class="ico">
                    <img :src="businessListImg[index]" alt="">
                  </div>
                  <div class="title">
                    <a :href="`${apiName}business?t=${index}`">{{item.name}}</a>
                  </div>
                  <div class="txt multi-line3">
                    {{businessListTxt[index] | filtersText}}
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div class="xinkeSec03">
        <div class="wrap">
          <div class="xinkeHd">
            <div class="xinkeHdTitle">
              解决方案
            </div>
            <div class="xinkeHdSub">
              SOLUTIONS
            </div>
          </div>
          <div class="xinkeSec03Box">
            <el-row :gutter="30">
              <el-col :span="8" v-for="(item, index) in soutionListData" :key="index">
                <div class="xinkeSec03Inner" :class="{xinkeSec03Inner01: index == 0}">
                  <div class="xinkeSec03Bg"></div>
                  <div class="pic">
                    <img :src="soutionListImg[index]" /> 
                  </div>
                  <div class="xinkeSec03Title">
                    <div class="line lineTop"></div>
                    <div class="line lineBottom"></div>
                    <a :href="`${apiName}competence?t=${index}`">{{item.name}}</a>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <div class="wrap" style="padding-top: 70px; padding-bottom: 60px;">
        <!-- 要闻 -->
        <!-- <div class="home_pre clearfloat">
          <div class="home_pre_l fl"><span>要闻头条</span></div>
          <div class="inner">
            <el-carousel height="58px" direction="vertical" :interval="2500" indicator-position="none">
              <el-carousel-item v-for="item in newsTop" :key="item.id">
                <p class="single-line">
                  <a v-if="!item.is_link || item.is_link === 0" class="intro-btn" target="_blank" :href="`/swth/detail?id=${item.id}`">{{ item.title }}</a>
                  <a v-if="item.is_link === 1" target="_blank" class="intro-btn" :href="item.link_url">{{ item.title }}</a>
                </p>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div> -->

        <div class="xinkeHd">
          <div class="xinkeHdTitle">
            新闻中心
          </div>
          <div class="xinkeHdSub">
            NEWS
          </div>
        </div>

        <!-- 新闻中心 -->
        <div class="homeNews" style="padding-top: 40px; padding-bottom: 30px;">
          <el-row :gutter="30">
            <el-col :span="12">
              <div class="swiper-container bannerHomeSec01">
                <div class="swiper-wrapper">
                  <div class="swiper-slide" v-for="(item, index) in newsListImg" :key="index">
                    <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}detail?id=${item.id}`">
                      <img :src="item.img" alt="">
                      <p class="single-line">{{item.title}}</p>
                    </a>
                    <a v-if="item.is_link === 1" target="_blank" class="intro-btn" :href="item.link_url">
                      <img :src="item.img" alt="">
                      <p class="single-line">{{item.title}}</p>
                    </a>
                  </div>
                </div>
                <div class="swiper-pagination swiper-pagination-sec01"></div>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="homeNewsBox">
                <!-- <div class="homeNewsHd clearfloat" style="padding-bottom: 4px;">
                  <div class="homeNewsHdInner trans" :class="{active : activeHd == index}" v-for="(item, index) in newHdList" :key="index" @mouseenter="mouseenterNewsHd(index)">{{item.name}}</div>
                </div> -->
                <ul v-if="activeHd == 0" class="news_list news_list02">
                  <li v-for="(item,index) in newsListCompany" :key="index" >
                    <div class="news_list_time fr">{{ dayjs(item.create_time).format('YYYY-MM-DD')  }}</div>
                    <p class="news_list_inner single-line">
                      <a v-if="!item.is_link || item.is_link === 0" target="_blank" :href="`${apiName}detail?id=${item.id}`">{{item.title}}</a>
                      <a v-if="item.is_link === 1" target="_blank" :href="item.link_url">{{item.title}}</a>
                    </p>
                  </li>
                </ul>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

    </div>

    <div class="tanBox">
      <div class="tanBoxPic">
        <a
          title="甘肃公航旅信息科技有限公司办公场所搬迁公告"
          href="https://apizq.ghatg.com/annex/1690858373657.pdf"
          target="_blank"
          rel="noopener"
          data-mce-href="https://apizq.ghatg.com/annex/1690858373657.pdf">
          <img :src="tanImg" alt="">
        </a>
        <p>
          公司办公场所<br>搬迁公告
        </p>
      </div>
    </div>

    <!-- <a title="65741a55a5085f40f3be4db1503efdfe_fabb3437afbe05df8508d1fe222c7bc5_8.pdf" href="https://apizq.ghatg.com/annex/1690858373657.pdf" target="_blank" rel="noopener" data-mce-href="https://apizq.ghatg.com/annex/1690858373657.pdf">65741a55a5085f40f3be4db1503efdfe_fabb3437afbe05df8508d1fe222c7bc5_8.pdf</a> -->
  </div>
</template>
<script>
import Swiper from "swiper"
export default {
  name: 'home',
  data(){
    return {
      tanImg: require('../assets/img/ico_notice11_01_01.png'),
      newsListCompany: [],
      newsListImg: [],
      businessListDataDemo: [],
      businessListData: [],
      // businessListTxt: [],
      businessListTxt: {},
      businessListImg: [
        require('../assets/img/ico_zhihuijiaotong.png'),
        require('../assets/img/ico_lv_lvyou.png'),
        require('../assets/img/ico_wangluotuopu.png'),
        require('../assets/img/ico_jinrong.png'),
        require('../assets/img/ico_zhihuichengshi.png'),
        require('../assets/img/ico_zhihuijiaotong.png'),
      ],
      businessList: [
        {
          title: '智慧交通',
          img: require('../assets/img/ico_zhihuijiaotong.png'),
          des: '公航旅信科公司基于智慧交 通领域的实际需求，提供“创新 产品+解决方案+工程交付+ 运营维护”的完整产业链服务， 多项自主研发的产品、服务、解 决方案持续赋能我国智慧交通行 业“综合交通、智慧交通、绿色 交通、平安交通”的发展。',
          url: `${this.apiName}business?t=0`
        },
        {
          title: '智慧旅游',
          img: require('../assets/img/ico_lv_lvyou.png'),
          des: '公航旅信科公司将通过云计算、大数据、物联网、区块链和卫星定位技术，搭建多维度的智慧交旅 服务体系，实现交通与旅游的“规划融合”、“功能融合”、“产业融合”、“运管融合”，提供全国领先、国 际一流的交旅融合平台，构建沿线旅游观光智慧公路，服务政府、企业及公众，实现“交通+旅游”一体化',
          url: `${this.apiName}business?t=1`
        },
        {
          title: '智能网联',
          img: require('../assets/img/ico_wangluotuopu.png'),
          des: '公航旅信科公司拥有涵盖车载终端、路侧设备、协同控制、云管 理服务平台的V2X智能网联全系列产品，应用场景可支持我国标准要 求的17种应用场景，涵盖安全、效率、环保多种应用。公司基于5G 移动通信',
          url: `${this.apiName}business?t=2`
        },
        {
          title: '智慧金融',
          img: require('../assets/img/ico_jinrong.png'),
          des: '公航旅信科公司依托于互联网技 术，运用大数据、人工智能、云计算 等金融科技手段，使金融行业在业务 流程、业务开拓和客户服务等方面',
          url: `${this.apiName}business?t=3`
        },
        {
          title: '智慧城市',
          img: require('../assets/img/ico_zhihuichengshi.png'),
          des: '公航旅信科公司利用物联网、大数据、云服务等技术，构建社区云平台，融合社区居民、物业公司、 政府多方位需求，深度助力社会治理向社区下沉，全面推进社会治理能力现代化',
          url: `${this.apiName}business?t=4`
        },
        {
          title: '智慧物联',
          img: require('../assets/img/ico_zhihuijiaotong.png'),
          des: '公航旅信科公司基于Al全系列产品及人工智能综合解决方案，建设“防控立体化、指挥体系化、数 据融合化、实战高效化”的智能物联体系',
          url: `${this.apiName}business?t=5`
        },
      ],
      soutionListData: [],
      soutionListTxt: [],
      soutionListImg: [
        require('../assets/img/home_s_img06.jpg'),
        require('../assets/img/home_s_img03.jpg'),
        require('../assets/img/home_s_img02.jpg'),
        require('../assets/img/home_s_img04.jpg'),
        require('../assets/img/home_s_img05.jpg'),
      ],
      soutionList: [
        {
          title: '云计算',
          img: require('../assets/img/home_s_img06.jpg'),
          url: `${this.apiName}competence?t=0`
        },
        {
          title: '交通大数据',
          img: require('../assets/img/home_s_img03.jpg'),
          url: `${this.apiName}competence?t=1`
        },
        {
          title: '视频人工智能',
          img: require('../assets/img/home_s_img02.jpg'),
          url: `${this.apiName}competence?t=2`
        },
        {
          title: '软件研发',
          img: require('../assets/img/home_s_img04.jpg'),
          url: `${this.apiName}competence?t=3`
        },
        {
          title: '创新培育',
          img: require('../assets/img/home_s_img05.jpg'),
          url: `${this.apiName}competence?t=4`
        },
      ],
      activeHd: 0,
      newHdList: [
        {
          name: '集团公司',
        },
        {
          name: '航投公司',
        },
        {
          name: '苏武公司',
        }
      ],
      listZfgs: [
        {
          name: '集团官网',
          url: 'https://www.ghatg.com/'
        },
        {
          name: '新华网',
          url: ''
        },
        {
          name: '人民网',
          url: ''
        },
        {
          name: '中国网',
          url: ''
        },
        {
          name: '中国网络电视台',
          url: ''
        },
        {
          name: '中国日报网',
          url: ''
        },
        {
          name: '国际在线',
          url: ''
        },
        {
          name: '中青在线',
          url: ''
        },
        {
          name: '中国经济网',
          url: ''
        },
        {
          name: '中国西藏网',
          url: ''
        },
        {
          name: '中国台湾网',
          url: ''
        },
        {
          name: '央广闷',
          url: ''
        },
        {
          name: '光明网',
          url: ''
        },
        {
          name: '中国新闻网',
          url: ''
        },
        {
          name: '学习强国',
          url: ''
        },
        {
          name: '中央纪委国家监察委网站',
          url: ''
        },
        {
          name: '党建网',
          url: ''
        },
        {
          name: '思想政治工作',
          url: ''
        },
        {
          name: '全国宣传干部学院',
          url: ''
        },
        {
          name: '形势政策网',
          url: ''
        },
        {
          name: '学习出版杜',
          url: ''
        },
        {
          name: '全国社科规划网',
          url: ''
        },
        {
          name: '每日甘肃网',
          url: ''
        },
        {
          name: '中国甘肃网',
          url: ''
        },
        {
          name: '中国兰州网',
          url: ''
        }
      ],
      newsTop: [],
      newsLeft: [],
      newsRight01: [],
      newsRight02: [],
      newsRight03: [],
      newsRight: [],
      newsList: [
        // {
        //   title: '集团公司领导赴焉支山景区调研指导工作',
        //   img: require('../assets/img/home_vedio_img01.jpg'),
        //   id: '1',
        //   cont: '9月15日，集团公司副总经理孙进玲一行赴焉支山景区调研指导工作，旅投集团负责人陪同调研旅投集团负责人陪同调研旅投集团负责人陪同调研。  孙进玲副总经理实地查看了焉支山景区',
        //   create_time: '2021-05-14'
        // },
      ],
      options01: [
        {
          name: '外交部',
          url: 'http://www.fmprc.gov.cn'
        },
        {
          name: '国防部',
          url: 'http://www.mod.gov.cn/'
        },
        {
          name: '发展改革委',
          url: 'http://www.ndrc.gov.cn/'
        },
        {
          name: '教育部',
          url: 'http://www.moe.edu.cn/'
        },
        {
          name: '科技部',
          url: 'http://www.most.gov.cn/'
        },
        {
          name: '中纪委',
          url: 'https://www.ccdi.gov.cn/'
        },
        {
          name: '工业和信息化部',
          url: 'http://www.miit.gov.cn/'
        },
        {
          name: '自然资源部',
          url: 'http://www.mnr.gov.cn/'
        },
        {
          name: '公安部',
          url: 'http://www.mps.gov.cn/'
        },
        {
          name: '民政部',
          url: 'http://www.mca.gov.cn'
        },
        {
          name: '司法部',
          url: 'http://www.moj.gov.cn'
        },
        {
          name: '财政部',
          url: 'http://www.mof.gov.cn'
        },
        {
          name: '人力资源社会保障部',
          url: 'http://www.mohrss.gov.cn'
        },
        {
          name: '生态环境部',
          url: 'http://www.mee.gov.cn'
        },
        {
          name: '住房城乡建设部',
          url: 'http://www.mohurd.gov.cn'
        },
        {
          name: '交通运输部',
          url: 'http://www.mot.gov.cn'
        },
        {
          name: '水利部',
          url: 'http://www.mwr.gov.cn/'
        },
        {
          name: '农业农村部',
          url: 'http://www.moa.gov.cn/'
        },
        {
          name: '商务部',
          url: 'http://www.mofcom.gov.cn'
        },
        {
          name: '文化旅游部',
          url: 'http://www.mct.gov.cn/'
        },
        {
          name: '国家卫生健康委员会',
          url: 'http://www.nhc.gov.cn'
        },
        {
          name: '应急管理部',
          url: 'http://www.chinasafety.gov.cn/'
        },
        {
          name: '人民银行',
          url: 'http://www.pbc.gov.cn'
        },
        {
          name: '审计署',
          url: 'http://www.audit.gov.cn/'
        },
        {
          name: '国资委',
          url: 'http://www.sasac.gov.cn/index.html'
        },
        {
          name: '海关总署',
          url: 'http://www.customs.gov.cn/publish/portal0/'
        },
        {
          name: '税务总局',
          url: 'http://www.chinatax.gov.cn/'
        },
        {
          name: '国家市场监督管理总局',
          url: 'http://www.aqsiq.gov.cn/'
        },
        {
          name: '国家广播电视总局',
          url: 'http://www.sapprft.gov.cn/'
        },
        {
          name: '体育总局',
          url: 'http://www.sport.gov.cn/'
        },
        {
          name: '统计局',
          url: 'http://www.stats.gov.cn/'
        },
        {
          name: '知识产权局',
          url: 'http://www.sipo.gov.cn/'
        },
        {
          name: '宗教局',
          url: 'http://www.sara.gov.cn/'
        },
        {
          name: '参事室',
          url: 'http://www.counsellor.gov.cn/'
        },
        {
          name: '国管局',
          url: 'http://www.ggj.gov.cn/'
        },
        {
          name: '版权局',
          url: 'http://www.ncac.gov.cn/'
        },
        {
          name: '法制办',
          url: 'http://www.chinalaw.gov.cn/'
        },
        {
          name: '国务院研究室',
          url: 'http://www.gov.cn/gjjg/2005-12/26/content_137261.htm'
        },
        {
          name: '台办',
          url: 'http://www.gwytb.gov.cn/'
        },
        {
          name: '新闻办',
          url: 'http://www.scio.gov.cn/'
        },
        {
          name: '新华社',
          url: 'http://www3.xinhuanet.com/'
        },
        {
          name: '中科院',
          url: 'http://www.cas.cn/'
        },
        {
          name: '社科院',
          url: 'http://cass.cssn.cn/'
        },
        {
          name: '工程院',
          url: 'http://www.cae.cn/'
        },
        {
          name: '发展研究中心',
          url: 'http://www.drc.gov.cn/'
        },
        {
          name: '中央党校',
          url: 'http://www.nsa.gov.cn/'
        },
        {
          name: '气象局',
          url: 'http://www.cma.gov.cn/'
        },
        {
          name: '地震局',
          url: 'http://www.cea.gov.cn/'
        },
        {
          name: '证监会',
          url: 'http://www.csrc.gov.cn/pub/newsite/'
        },
      ],
      options02: [
        {
          name: '甘肃省公路航空旅游投资集团有限公司',
          url: 'http://www.ghatg.com/'
        },
        {
          name: '金川集团有限公司',
          url: 'http://www.jnmc.com/'
        },
        {
          name: '酒泉钢铁集团',
          url: 'http://www.jiugang.com/'
        },
        {
          name: '白银有色集团股份有限公司',
          url: 'http://www.bynmc.com/'
        },
        {
          name: '甘肃建投',
          url: 'http://www.gsjtw.cc/'
        },
      ],
      options03: [
        {
          name: '省国资委',
          url: 'http://gzw.gansu.gov.cn/'
        },
        {
          name: '省发展改革委',
          url: 'http://fzgg.gansu.gov.cn/'
        },
        {
          name: '省教育厅',
          url: 'http://jyt.gansu.gov.cn/'
        },
        {
          name: '省科技厅',
          url: 'http://kjt.gansu.gov.cn/'
        },
        {
          name: '省工信厅',
          url: 'http://gxt.gansu.gov.cn/'
        },
        {
          name: '省政府国资委',
          url: 'http://gzw.gansu.gov.cn/'
        },
        {
          name: '省纪委',
          url: 'http://www.gsjw.gov.cn/'
        },
        {
          name: '省民族事务委员会',
          url: 'http://mzsw.gansu.gov.cn/'
        },
        {
          name: '省公安厅',
          url: 'http://gat.gansu.gov.cn/'
        },
        {
          name: '省民政厅',
          url: 'http://mzt.gansu.gov.cn/'
        },
        {
          name: '省司法厅',
          url: 'http://sft.gansu.gov.cn/'
        },
        {
          name: '省财政厅',
          url: 'http://czt.gansu.gov.cn/'
        },
        {
          name: '省人社厅',
          url: 'http://rst.gansu.gov.cn/'
        },
        {
          name: '省自然资源厅',
          url: 'http://zrzy.gansu.gov.cn/'
        },
        {
          name: '省生态环境厅',
          url: 'http://sthj.gansu.gov.cn/'
        },
        {
          name: '省住建厅',
          url: 'http://zjt.gansu.gov.cn/'
        },
        {
          name: '省交通运输厅',
          url: 'http://jtys.gansu.gov.cn/'
        },
        {
          name: '省水利厅',
          url: 'http://slt.gansu.gov.cn/'
        },
        {
          name: '省农业农村厅',
          url: 'http://nync.gansu.gov.cn/'
        },
        {
          name: '省商务厅',
          url: 'http://swt.gansu.gov.cn/'
        },
        {
          name: '省文旅厅',
          url: 'http://wlt.gansu.gov.cn/'
        },
        {
          name: '省卫生健康委',
          url: 'http://wsjk.gansu.gov.cn/'
        },
        {
          name: '省退役军人厅',
          url: 'http://tyjr.gansu.gov.cn/'
        },
        {
          name: '省应急厅',
          url: 'http://yjgl.gansu.gov.cn/'
        },
        {
          name: '省审计厅',
          url: 'http://sjt.gansu.gov.cn/'
        },
        {
          name: '省政府外事办',
          url: 'http://wsb.gansu.gov.cn/'
        },
      ],
      options04: [
        {
          name: '浙江省交通投资集团有限公司',
          url: 'http://www.cncico.com/'
        },
        {
          name: '江苏交通控股有限公司',
          url: 'http://www.jchc.cn'
        },
        {
          name: '广西交通投资集团',
          url: 'http://www.gx.xinhuanet.com/wq/gxjt/'
        },
        {
          name: '广东省交通投资集团',
          url: 'http://www.gdcg.com.cn'
        },
        {
          name: '山东高速集团有限公司',
          url: 'http://www.sdhsg.com'
        },
        {
          name: '河北建设投资集团',
          url: 'http://www.hecic.com.cn'
        },
        {
          name: '河南高速公路发展有限',
          url: 'http://www.hnew.com.cn'
        },
        {
          name: '江西省投资集团公司',
          url: 'http://www.jxic.com/'
        }
      ],
      options05: [
        {
          name: '国家开发银行',
          url: 'http://www.cdb.com.cn'
        },
        {
          name: '中国进出口银行',
          url: 'http://www.eximbank.gov.cn'
        },
        {
          name: '中国农业发展银行',
          url: 'http://www.adbc.com.cn'
        },
        {
          name: '中国工商银行',
          url: 'http://www.icbc.com.cn'
        },
        {
          name: '中国农业银行',
          url: 'http://www.abchina.com'
        },
        {
          name: '中国银行',
          url: 'http://www.bank-of-china.com'
        },
        {
          name: '中国建设银行',
          url: 'http://www.ccb.com/cn/home/indexv3.html'
        },
        {
          name: '交通银行',
          url: 'http://www.bankcomm.com'
        },
        {
          name: '招商银行',
          url: 'http://www.cmbchina.com'
        },
        {
          name: '信托网',
          url: 'http://www.trust-one.com'
        }
      ],
      value01: '政府及有关部门',
      value02: '集团及所属公司',
      value03: '综合网站',
      value04: '周边服务',
      newsBusiness01: [],
      newsBusiness02: [],
      newsBusiness03: [],
      newsBusiness04: [],
      businessLength: 0
    }
  },
  mounted(){
    this.bannerInit()
    this.getImgNews()
    this.companyNews()
    this.getBusinessData()
    this.getSoluData()
  },
  methods: {
    // 新闻头条
    async getImprotNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImportNews', {company_id: _this.companyId});
      _this.newsTop = data.data.list;
    },
    // 焦点图
    async getImgNews(){
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getImgNews', {company_id: _this.companyId});
      _this.newsListImg = data.data.list.slice(0,6);
      this.$nextTick(() => {
        _this.bannerHomeSec01()
      })
    },
    // 公司新闻
    async companyNews() {
      var _this = this;
      // 集团集团公司
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: "392", page: 1, pageSize: 10});
      _this.newsListCompany = data.data.list;
    },
    // 核心业务
    async getBusinessData() {
      var _this = this;
      
      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "118"});
      _this.businessListDataDemo = data.data.slice(0,6);
      _this.businessLength = _this.businessListDataDemo.length
      _this.businessListDataDemo.length && _this.businessListDataDemo.forEach((line, index) => {
        _this.getBusinessDataTxt(line.id, index)
      })
    },
    async getBusinessDataTxt(id, index) {
      var _this = this;

      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', {group_id: id, page: 1});
      // _this.businessListData = data.data;
      _this.getBusinessDataTxtSub(data.data.list[0].id, index)
    },
    async getBusinessDataTxtSub(id, index) {
      var _this = this;

      var { data } = await _this.$http.post(_this.apiUrl + '/news/getBackstageNewsDetail', {id: id});
      // _this.businessListData = data.data.content;
      // _this.businessListTxt.push(data.data.content || '')
      _this.businessListTxt[index] = data.data.content ? this.setText(data.data.content) : ''
      if (_this.businessLength == index + 1) {
        setTimeout(() => {
          _this.businessListData = [..._this.businessListDataDemo]
        }, 500)
      }
    },
    // 解决方案
    async getSoluData() {
      var _this = this;

      var { data } = await _this.$http.post(_this.apiUrl + '/group/getGrouplistByNavId', {id: "119"});
      _this.soutionListData = data.data.slice(0,5);
    },
    // banner
    bannerInit(){
      new Swiper(".bannerHome", {
        effect : 'fade',
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-banner",
          clickable :true,
          // type: 'fraction',
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 焦点图
    bannerHomeSec01(){
      // var _this = this
      
      new Swiper(".bannerHomeSec01", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-sec01",
          clickable :true,
          // renderBullet: function (index, className) {
          //   return '<div class="day ' + className + '"><div class="day">' + _this.newsListImg[index].update_time.slice(8, 10) + '</div><div class="time">' + _this.newsListImg[index].update_time.slice(0, 4) + '.' + _this.newsListImg[index].update_time.slice(5, 7) + '</div></div>';
          // },
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 专题专栏
    bannerSpecail(){
      new Swiper(".bannerSpecailN", {
        direction : 'vertical',
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        pagination: {
          el: ".swiper-pagination-SpecailN",
          clickable :true,
        },
        // slidesPerView : 5,
        // spaceBetween : 15,
        navigation: {
          nextEl: '.swiper-button-next-specail',
          prevEl: '.swiper-button-prev-specail',
          hideOnClick: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 景区风采
    bannerPoint(){
      new Swiper(".bannerPoint", {
        autoplay: {
          disableOnInteraction: false,
        },
        loop: true,
        slidesPerView : 4,
        spaceBetween : 15,
        navigation: {
          nextEl: '.swiper-button-next-point',
          prevEl: '.swiper-button-prev-point',
          hideOnClick: true,
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
        autoplayDisableOnInteraction: false,   //用户操作swiper之后重新启动autoplay
      })
    },
    // 提取纯文本
    setText(val) {
      if (val != null && val != "") {
        var re1 = new RegExp("<.+?>|&.+?;","g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
        var msg = val.replace(re1,""); //执行替换成空字符
        msg = msg.replace(/\s/g,""); //去掉所有的空格（中文空格、英文空格都会被替换）
        msg = msg.replace(/[\r\n]/g,""); //去掉所有的换行符
        return msg.substr(0, 100); //获取文本内容的前100个字符
      } else return ""
    },
  }
}
</script>
<style scoped>
.bannerHome {
  height: 620px;
  position: relative;
}
.bannerHome .swiper-wrapper,
.bannerHome .swiper-slide,
.bannerHome img {
  height: 100%;
  -moz-transform: scale(1.0);
  -webkit-transform: scale(1.0);
  -o-transform: scale(1.0);
  transform: scale(1.0);
  -moz-transition: all 3s linear;
  -webkit-transition: all 3s linear;
  -o-transition: all 3s linear;
  transition: all 3s linear;
}
.bannerHome img {
  width: 100%;
}
.bannerHome .swiper-slide.swiper-slide-active img {
  -moz-transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}
.swiper-pagination-banner {
  color: #fff;
  font-size: 16px;
}

.swiper-pagination-current {
  font-size: 20px;
}
.secBox01 {
  padding: 60px 0;
  background-image: url(../assets/img/home_bg_sec01.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.secBox01Hd {
  margin-bottom: 30px;
  line-height: 48px;
}
.secBox01Title {
  font-size: 40px;
  color: #8A8A8A;
  text-transform: Uppercase;
}
.secBox01Txt {
  font-size: 30px;
  color: #535353;
}
.secBox01Tab {
  margin-bottom: 54px;
  text-align: center;
}
.secBox01Tab span {
  display: inline-block;
  height: 30px;
  line-height: 30px;
  padding: 0 18px;
  margin: 0 26px;
  font-size: 16px;
  color: #282828;
  background-color: #fff;
  cursor: pointer;
  border-radius: 20px;
}
.secBox01Tab span.active {
  color: #fff;
  background-color: #BC8F68;
}
.bannerHomeSec01 {
  height: 408px;
  position: relative;
}
.bannerHomeSec01 .swiper-wrapper,
.bannerHomeSec01 .swiper-slide,
.bannerHomeSec01 img {
  height: 100%;
}
.bannerHomeSec01 img {
  width: 100%;
  height: 100%;
}
.bannerHomeSec01 .swiper-slide {
  position: relative;
}
/* .bannerHomeSec01 p {
  position: absolute;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 118px 0 12px;
  left: 0;
  bottom: 0;
  color: #fff;
  font-size: 16px;
  background-color: rgba(0,0,0,0.8);
  box-sizing: border-box;
  text-align: left;
} */
.bannerHomeSec01 .swiper-pagination-bullet {
  /* width: 44px;
  height: 44px; */
  width: 12px;
  height: 44px;
  text-align: center;
  border-radius: 0;
  background-color:rgba(0, 0, 0, 0.2);
  opacity: 1;
}
.bannerHomeSec01 .swiper-pagination-bullet-active {
  background-color:#CE251F;
}
.bannerHomeSec01 .swiper-pagination {
  text-align: right;
  left: auto;
  right: 8px;
}
/* 友情链接 */
.sec_zfgs_list a {
    display: block;
    text-align: center;
    line-height: 34px;
    border: 1px solid #d5d5d5;
    margin-bottom: 6px;
}

.sec_zfgs_list a:hover {
    border-color: #2b78c6;
    color: #fff;
    background-color: #2b78c6;
}
.homeNewsHdInner {
  display: inline-block;
  padding: 0 20px;
  font-size: 16px;
  background-color: #f0f0f0;
  color: #333;
  height: 38px;
  line-height: 38px;
  cursor: pointer;
  margin-right: 1px;
}
.homeNewsHdInner.active {
  background-color: #2b78c6;
  color: #fff;
}
.homeXinke {
  padding-top: 100px;
}
.homeXinke .bannerHome {
  height: 520px;
}
.tanBox {
  position: fixed;
  bottom: 5%;
  right: 10px;
  text-align: center;
  z-index: 222;
}
.tanBoxPic {
  width: 100px;
}
.tanBoxPic a {
  display: block;
}
.tanBoxPic img {
  width: 100%;
  height: auto;
}
.tanBoxPic p {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  margin-top: 10px;
}
</style>